<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'HbBox',
  props: {
    inline: { type: Boolean, default: true },
  },
  setup(props) {
    const classes = computed(() => ({
      'hb-box': true,
      'hb-box--inline': props.inline,
    }))

    return {
      classes,
    }
  },
}
</script>

<style lang="scss">
.hb-box {
  display: flex;
  padding: 15px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  backdrop-filter: blur(3px);

  &--inline {
    display: inline-flex;
  }
}
</style>
